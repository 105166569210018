/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/indent */
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Location } from '@components/Hotels/types';
import { CalendarIcon, LocationIcon } from '@resortpass/solar-icons';
import Button from '@solar/common/Button/Button';
import Paragraph from '@solar/common/Paragraph/Paragraph';
import DatePicker from '@components/SearchExperiment/SearchControls/DatePicker';
import LocationsSearchDrawer from '@components/SearchExperiment/SearchControls/LocationsSearchDrawer';
import { applyUserLocation } from '@helpers/location';
import { getLocations } from '@utils/services';
import { SEARCH_DEFAULT_LOCATIONS } from '@constants/SearchDefaultOptions';
import { filter } from 'lodash';
import { useSearchContext } from '@context/SearchContext2025';
import { getFormattedDate, parseDateWithDashes, getDateToUse } from '@helpers/date';
import { useDrawer } from '@context/DrawerContext';
import { useUserLocation } from '@context/GeoIpDetectionContext';
import useValidSearchStateDate from '@hooks/useValidSearchStateDate';
import useDebounce from '@hooks/useDebounce';
import {
  useAmplitudeTypeaheadTracker,
  useAmplitudeStartSelectSearchLocationTracker,
  useAmplitudeFlexibleDateTracker,
  useAmplitudeDateCancelTracker,
} from '@hooks/useAmplitudeTrackers';

export default function MobileSearchControls() {
  const {
    setCountryCode,
    setCity,
    setHotelName,
    setStateCode,
    setSearchTerm,
    setAliasId,
    getDate,
    setDate,
    search,
    updateSearchState,
    searchParams,
  } = useSearchContext();
  const { search_term: searchTerm, date } = searchParams;
  useValidSearchStateDate(searchParams, updateSearchState);
  const { rerender, closeDrawer, openDrawerWithComponent } = useDrawer();
  const userLocation = useUserLocation();
  // -------------------------------------------------------------------
  // Typeahead State ---------------------------------------------------
  // -------------------------------------------------------------------
  const [typeaheadInput, setTypeaheadInput] = useState(userLocation?.name || '');
  const [typeAheadResults, setTypeAheadResults] = useState<Location[]>([]);

  // -------------------------------------------------------------------
  // DatePicker State --------------------------------------------------
  // -------------------------------------------------------------------
  const formattedDate = useMemo(() => getFormattedDate(getDate()), [getDate]);
  // drawer state
  const [drawerDateTab, setDrawerDateTab] = useState<'date' | 'flexible'>(
    date === 'any' ? 'flexible' : 'date',
  );
  const [datepickerDate, setDatepickerDate] = useState<Date>(
    date === 'any' || date === '' ? getDateToUse() : parseDateWithDashes(date),
  );

  // Amplitude Trackers
  const amplitudeTypeaheadSelectTracker = useAmplitudeTypeaheadTracker();
  const amplitudeStartSelectSearchLocationTracker = useAmplitudeStartSelectSearchLocationTracker();
  const amplitudeFlexibleDateTracker = useAmplitudeFlexibleDateTracker();
  const amplitudeCancelSearchDateTracker = useAmplitudeDateCancelTracker();

  // -------------------------------------------------------------------
  // Typeahead Handlers ------------------------------------------------
  // -------------------------------------------------------------------
  const defaultResults = useMemo(() => {
    if (userLocation) {
      return [
        userLocation,
        ...filter(SEARCH_DEFAULT_LOCATIONS, (loc) => loc.id !== userLocation.id),
      ];
    }
    return SEARCH_DEFAULT_LOCATIONS;
  }, [userLocation]);

  const fetchTypeaheadResults = useDebounce(async (term: string) => {
    const trimmed = term.trim();
    if (trimmed) {
      const locations = await getLocations(`"${term.trim()}"`);
      setTypeAheadResults(locations);
    } else {
      setTypeAheadResults(defaultResults);
    }
    if (trimmed.length > 0) amplitudeStartSelectSearchLocationTracker();
  }, 300);

  /**
   * Handle when a location is selected from the typeahead
   */
  const handleOnLocationSelect = useCallback(
    (location: Location) => {
      closeDrawer();
      setTypeaheadInput(location.name);
      setTypeAheadResults([]);
      applyUserLocation(location, {
        setCountryCode,
        setCity,
        setHotelName,
        setStateCode,
        setSearchTerm,
        setAliasId,
      });
      amplitudeTypeaheadSelectTracker(location);
    },
    [
      closeDrawer,
      setTypeAheadResults,
      setCountryCode,
      setCity,
      setHotelName,
      setStateCode,
      setSearchTerm,
      setAliasId,
      setTypeaheadInput,
      amplitudeTypeaheadSelectTracker,
    ],
  );

  /**
   * Handle when the typeahead input is clicked / Focused
   */
  const handleOnInputClick = useCallback(() => {
    if (typeaheadInput) {
      fetchTypeaheadResults(typeaheadInput);
    } else {
      setTypeAheadResults(defaultResults);
    }
  }, [typeaheadInput, fetchTypeaheadResults, defaultResults]);

  /**
   * Handle when the typeahead input value changes
   */
  const handleOnSearchTermChange = useCallback(
    (term: string) => {
      setTypeaheadInput(term);
      fetchTypeaheadResults(term);
    },
    [setTypeaheadInput, fetchTypeaheadResults],
  );

  /**
   * Handle when the typeahead "x" is pressed
   */
  const handleOnClearInput = useCallback(() => {
    setTypeaheadInput('');
    setTypeAheadResults(defaultResults);
  }, [setTypeaheadInput, setTypeAheadResults, defaultResults]);

  // -------------------------------------------------------------------
  // The Typeahead Locations Drawer Component --------------------------
  // -------------------------------------------------------------------
  const searchComponentKey = 'search-location';
  const locationSearchDrawer = useMemo(
    () => (
      <LocationsSearchDrawer
        value={typeaheadInput}
        locations={typeAheadResults}
        onClose={closeDrawer}
        onInputClick={handleOnInputClick}
        onChange={handleOnSearchTermChange}
        onClear={handleOnClearInput}
        onSelect={handleOnLocationSelect}
        userLocation={userLocation}
      />
    ),
    [
      handleOnInputClick,
      handleOnSearchTermChange,
      handleOnClearInput,
      handleOnLocationSelect,
      closeDrawer,
      typeAheadResults,
      typeaheadInput,
      userLocation,
    ],
  );

  // rerender when the component changes
  useEffect(() => {
    rerender(searchComponentKey, locationSearchDrawer);
  }, [locationSearchDrawer, rerender]);

  /**
   * Handle when the search location input control is clicked/focused
   */
  const handleLocationClick = () => {
    handleOnInputClick();
    openDrawerWithComponent(locationSearchDrawer, searchComponentKey, {
      height: '99dvh',
    });
  };

  // -------------------------------------------------------------------
  // DatePicker Handlers -----------------------------------------------
  // -------------------------------------------------------------------

  /*
   * Handle when the datepicker date changes. This
   * only updates the "local" state of the datepicker.
   * The actual date is set when the search button is clicked.
   * @param selectedDate - The selected date
   */
  const handleDrawerDateChange = useCallback(
    (selectedDate?: Date) => {
      if (selectedDate) {
        setDatepickerDate(selectedDate);
      }
    },
    [setDatepickerDate],
  );

  /**
   * Handle when the datepicker tab changes.
   * This only updates the "local" state of the datepicker.
   */
  const handleDrawerDateTabChange = useCallback(
    (tab: 'date' | 'flexible') => {
      if (tab === 'flexible') {
        setDate('any');
        closeDrawer();
        amplitudeFlexibleDateTracker(searchParams);
      }
      setDrawerDateTab(tab);
    },
    [closeDrawer, setDrawerDateTab, setDate, amplitudeFlexibleDateTracker, searchParams],
  );

  const handleApplyButtonClick = useCallback(() => {
    if (drawerDateTab === 'flexible') setDate('any');
    if (drawerDateTab === 'date' && datepickerDate) {
      setDate(datepickerDate);
    }
    closeDrawer();
  }, [drawerDateTab, datepickerDate, setDate, closeDrawer]);

  const handleCancel = useCallback(() => {
    amplitudeCancelSearchDateTracker(searchParams);
    closeDrawer();
  }, [closeDrawer, searchParams, amplitudeCancelSearchDateTracker]);

  const dateComponentKey = 'date-picker';
  const datePicker = useMemo(
    () => (
      <DatePicker
        tab={drawerDateTab}
        date={datepickerDate}
        onTabClick={handleDrawerDateTabChange}
        onDateChange={handleDrawerDateChange}
        onApplyButtonClick={handleApplyButtonClick}
        onCancelClick={handleCancel}
        showButtons
      />
    ),
    [
      drawerDateTab,
      datepickerDate,
      handleCancel,
      handleDrawerDateChange,
      handleDrawerDateTabChange,
      handleApplyButtonClick,
    ],
  );

  const handleDatePickerClick = useCallback(() => {
    openDrawerWithComponent(datePicker, dateComponentKey, { height: '75dvh' });
  }, [openDrawerWithComponent, datePicker]);

  // Rerender the drawer when the tab or date changes
  useEffect(() => {
    rerender(dateComponentKey, datePicker);
  }, [rerender, datePicker]);

  return (
    <div className="z-20 flex flex-col items-center rounded-solar-sm">
      <div
        onClick={handleLocationClick}
        onKeyDown={handleLocationClick}
        role="button"
        tabIndex={-1}
        className="relative flex items-center flex-1 flex-shrink-0 w-full border border-solar-secondary p-spacing-sm py-10px rounded-t-solar-sm space-x-spacing-sm"
      >
        <LocationIcon size={24} />

        <Paragraph size="sm" className={searchTerm ? 'text-solar-primary' : 'text-solar-secondary'}>
          {searchTerm || 'City, State, or Hotel'}
        </Paragraph>
      </div>

      <div className="relative flex flex-col justify-center flex-1 flex-shrink-0 w-full border border-t-0 border-solar-secondary min-w-124 p-spacing-sm py-10px rounded-b-solar-sm">
        <div
          onClick={handleDatePickerClick}
          onKeyDown={handleDatePickerClick}
          role="button"
          tabIndex={-1}
          className="flex flex-row items-center cursor-pointer space-x-spacing-sm"
        >
          <CalendarIcon size={24} />

          <Paragraph size="sm">{formattedDate}</Paragraph>
        </div>
      </div>

      <div className="flex items-center flex-shrink-0 w-full mt-spacing-lg">
        <Button color="primarySRP" size="lg" onClick={search} fullWidth>
          Search
        </Button>
      </div>
    </div>
  );
}
