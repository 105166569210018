import { useEffect, useRef } from 'react';
import { SearchState } from '@customTypes/search';
import { getDateWithDashes, parseDateWithDashes } from '@helpers/date';

/**
 * useValidSearchState is a custom hook that ensures the search date is not in the past.
 * @param {SearchState} searchState - The current search state.
 * @param {Function} callback - The function to call with the new search state.
 */
export default function useValidSearchState(
  searchState: SearchState,
  callback: (s: SearchState) => void,
) {
  const cb = useRef(callback);
  useEffect(() => {
    cb.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!searchState.date || searchState.date === 'any') return;
    const now = new Date();
    const currentDate = parseDateWithDashes(searchState.date);
    if (now > currentDate && getDateWithDashes(now) !== searchState.date) {
      const date = getDateWithDashes(now);
      const newSearch = JSON.parse(JSON.stringify({ ...searchState, date }));
      if (cb.current) cb.current(newSearch);
    }
  }, [searchState, callback]);
}
